<template>
  <b-row class="no-gutters ml-n3">
    <b-col class="col-12 col-md-6 col-lg-4 pl-3">
      <search-input
        v-model="searchInput"
        label="search"
        :placeholder="$t('page.post.searchPlaceholder')"
        @search="search"
      ></search-input>
    </b-col>
    <b-col class="col-12 col-md-6 col-lg-4 pl-3">
      <form-select v-model="filterCondominium" label="condominium">
        <b-form-select-option :value="null">
          {{ $t("all") }}
        </b-form-select-option>
        <b-form-select-option
          v-for="(condominium, index) in condominiumOptions"
          :key="index"
          :value="condominium"
        >
          {{ condominium.name }}
        </b-form-select-option>
      </form-select>
    </b-col>
    <b-col class="align-self-center pl-4 pt-3">
      <b-form-checkbox
        id="report_status"
        v-model="reportedFilter"
        name="report_status"
      >
        {{ $t("button.showReported") }}
      </b-form-checkbox>
    </b-col>
  </b-row>
</template>

<script>
import SearchInput from "./../../../../console-components/BaseSearchInput";
import FormSelect from "./../../../../console-components/BaseFormSelect";
import { SORT_FILTERS } from "./../../Utils";

export default {
  components: {
    SearchInput,
    FormSelect
  },
  data() {
    return {
      searchInput: ""
    };
  },
  mounted() {
    this.searchInput = this.filterSearch;
  },
  computed: {
    filterSearch: {
      get() {
        return this.$store.getters["post/getFilterSearch"];
      },
      set(value) {
        this.$store.commit("post/SET_FILTER_SEARCH", value);
      }
    },
    filterCondominium: {
      get() {
        return this.$store.getters["post/getFilterCondominium"];
      },
      set(value) {
        this.$store.commit("post/SET_FILTER_CONDOMINIUM", value);
        this.retrievePosts();
      }
    },
    reportedFilter: {
      get() {
        return this.$store.getters["post/getFilterReported"];
      },
      set(value) {
        this.$store.commit("post/SET_FILTER_REPORTED", value);
        this.retrievePosts();
      }
    },
    condominiumOptions() {
      const filteredCondominums = this.$store.getters[
        "condominium/getCondominiums"
      ].filter(condominium => {
        return condominium.status === "pro" || condominium.status === "free";
      });
      return SORT_FILTERS(filteredCondominums, "name");
    }
  },
  methods: {
    search() {
      this.filterSearch = this.searchInput;
      this.retrievePosts();
    },
    async retrievePosts() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("post/retrieveUserPosts");
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
