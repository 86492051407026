<template>
  <div>
    <h3 class="mb-5 text-secondary">{{ $t("page.post.title") }}</h3>
    <post-filters class="mb-5" />
    <post-table
      :posts="formatedPosts"
      :fields="fields"
      :actions="actions"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    />
  </div>
</template>

<script>
import { userMixin, appMixin } from "./../mixins";

import PostFilters from "./../components/Post/PostFilters";
import PostTable from "./../components/Post/PostTable";
export default {
  components: {
    PostFilters,
    PostTable
  },
  mixins: [userMixin, appMixin],
  data() {
    return {
      actions: [
        {
          label: "button.manage",
          variant: "secondary",
          callbackFunc: this.goToPostDetails
        },
        {
          label: "button.delete",
          variant: "orange",
          callbackFunc: this.openDeleteConfirmationModal
        }
      ]
    };
  },
  computed: {
    fields() {
      const initFields = [
        { key: "condominiumName", label: "condominium", sort: true },
        { key: "createdAt", label: "date", sort: true },
        { key: "body", label: "postContent", sort: true },
        { key: "photo", label: "photo", sort: false },
        { key: "commentsNumber", label: "commentsNumber", sort: true },
        { key: "author", label: "author", sort: true },
        // { key: "role", label: "roleInCondominium", sort: false },
        // { key: "group", label: "groupInCondominium", sort: false },
        { key: "reported", label: "reported", sort: false },
        { key: "actions", label: "" }
      ];
      if (this.$is1Board)
        initFields.splice(6, 0, {
          key: "group",
          label: "groupInCondominium",
          sort: false
        });
      else
        initFields.splice(6, 0, {
          key: "role",
          label: "roleInCondominium",
          sort: false
        });
      return initFields;
    },
    posts() {
      return this.$store.getters["post/getPosts"].map(post => ({
        ...post,
        ownerId: post.ownerId || {
          role: "deleted",
          profile: {
            name: this.$t("userRoles.deleted"),
            surname: this.$t("userRoles.deleted")
          }
        }
      }));
    },
    formatedPosts() {
      return this.posts.map(item => {
        return {
          id: item._id,
          condominiumId: item.condominiumId._id,
          condominiumName: item.condominiumId.name || "-",
          createdAt: item.createdAt,
          body: item.body,
          photo: item.photo,
          commentsNumber: item.comments?.filter(comment => comment.ownerId)
            .length,
          reported:
            item.reported || item.comments.find(comment => comment.reported),
          authorAvatar: this.$_getUserProfilePhoto(item.ownerId.profile),
          author: this.$_getUserFullName(item.ownerId.profile),
          role: item.ownerId.condominiums
            ? this.getRoleInCondominium(item.condominiumId._id, item.ownerId)
            : item.ownerId.role
        };
      });
    },
    sortBy: {
      get() {
        return this.$store.getters["post/getSortBy"];
      },
      set(value) {
        this.$store.commit("post/SET_SORT_BY", value);
      }
    },
    sortDesc: {
      get() {
        return this.$store.getters["post/getSortDesc"];
      },
      set(value) {
        this.$store.commit("post/SET_SORT_DESC", value);
      }
    }
  },
  methods: {
    getRoleInCondominium(condominiumId, owner) {
      return (
        owner.condominiums.find(c => {
          return c.condominiumId === condominiumId;
        })?.role || "-"
      );
    },
    goToPostDetails(postId, condominiumId) {
      this.$router.push({
        name: "PostDetails",
        params: { condominiumId, postId }
      });
    },
    openDeleteConfirmationModal(postId, condominiumId) {
      this.$store.commit("confirm/SET_CONFIRM", {
        confirmBtnCallback: this.deletePost,
        confirmBtnParams: { postId, condominiumId },
        type: "DELETE"
      });
    },
    async deletePost({ postId, condominiumId }) {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        await this.$store.dispatch("post/deletePost", {
          postId,
          condominiumId
        });

        await this.$store.dispatch("post/retrieveUserPosts");
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
